$(function() {
    var url = location.href.indexOf('localhost') > 0 ||  location.href.indexOf('127.0.0.1:9000') > 0 ? 'https://cms.app-test.intra.uni-nke.hu' : window.location.origin;
    
    $.ajax({
        url: url + '/api/v1/public/youtube',
        success: function (data) {
            
            data.forEach(video => {
            $('#youtubeWrapper').append(`
                <div class="small-video">
                    <iframe width="560" height="315"
                            src="${video.url}" frameborder="0"
                            allowfullscreen></iframe>
                    <div class="title">${video.title}</div>
                </div>`
            )
            });
        }
    });
});
    
    
